import Vue from 'vue'
import isEmpty from 'lodash/isEmpty'
import { on } from 'dom-event'
import { HEADER_MOBILE_BREAKPOINT } from '@/lib/consts'
import '../picture/picture.vue'

Vue.component('menu-item', {
  data() {
    return {
      isOnMobile: window.innerWidth <= HEADER_MOBILE_BREAKPOINT,
      openNavSearch: false,
      isMenuOpened: false,
      activeClass: 'is-active',
      submenuContentHeight: '0'
    }
  },
  watch: {
    isMenuOpened(val) {
      if (val && !this.isOver(HEADER_MOBILE_BREAKPOINT)) {
        const unsetSubmenuContentHeight = () => {
          this.submenuContentHeight = 'unset'
          this.$refs.subMenu.removeEventListener(
            'transitionend',
            unsetSubmenuContentHeight
          )
        }
        this.submenuContentHeight = this.getSubmenuContentHeight()
        this.$refs.subMenu.addEventListener(
          'transitionend',
          unsetSubmenuContentHeight
        )
      } else if (!val) {
        if (this.isOnMobile) {
          setTimeout(() => {
            this.$refs.menuItemTitleTrigger.focus()
          }, 200)

          this.submenuContentHeight = '0px'
        }
      }
    },
  },
  mounted() {
    const subMenu = this.$refs.subMenu
    if (subMenu) {
      this.addSubmenuHandlers(subMenu)
    }

    this.closeOnWindowResize()
    this.setHeightProperty()

    on(window, 'resize', () => {
      this.isOnMobile = window.innerWidth <= HEADER_MOBILE_BREAKPOINT
    })
  },
  methods: {
    setHeightProperty() {
      const groupLinks = this.$el.querySelectorAll('.js-submenu-item-links')
      if (groupLinks && groupLinks.length) {
        groupLinks.forEach(group => {
          group.style.setProperty('--list-links-content-height', `${group.scrollHeight}px`)
        })
      }
    },
    isOver() {
      return window.innerWidth >= HEADER_MOBILE_BREAKPOINT
    },
    openSubMenu() {
      this.isMenuOpened = true

      setTimeout(() => {
        const subNavEl = this.$refs.subMenu
        if (subNavEl && this.isOver()) {
          subNavEl.style.height = `${subNavEl.children[0].offsetHeight}px`
        }
      })
    },
    closeSubMenu() {
      this.submenuContentHeight = this.$refs.subMenu
        ? `${this.$refs.subMenu.scrollHeight}px`
        : '0px'

      setTimeout(() => {
        this.isMenuOpened = false

        const subNavEl = this.$refs.subMenu
        if (subNavEl) {
          subNavEl.style.height = ''
        }
      }, 50)
    },
    close() {
      const links = document.querySelectorAll('.js-menu-item')
      if (links && links.length) {
        links.forEach((link) => {
          link.__vue__.closeSubMenu()
        })
      }
    },
    toggleSubmenuByClick(e) {
      const parentNode = e.target.parentNode
      const hasLink = !isEmpty(parentNode?.href)

      if (!this.isOver() && hasLink) return

      if (this.isMenuOpened) {
        this.close()
        this.closeSubMenu()
      } else {
        this.close()

        setTimeout(() => {
          this.openSubMenu()
        }, 100)
      }
    },
    onFocusedIn() {
      if (!this.isOnMobile) {
        this.close()
      }
    },
    addSubmenuHandlers(subMenu) {
      const groupItems = subMenu.querySelectorAll('a')
      const firstItem = groupItems[0]
      const lastItem = groupItems[groupItems.length - 1]

      if (firstItem) {
        firstItem.addEventListener('keydown', (event) => {
          if (event.keyCode === 9 && event.shiftKey) {
            this.closeSubMenu()
          }
        })
      }

      if (lastItem) {
        lastItem.addEventListener('keydown', (event) => {
          if (event.keyCode === 9 && !event.shiftKey) {
            this.closeSubMenu()
          }
        })
      }

      const link = this.$el.querySelector('.js-menu-item-link')

      if (link) {
        link.addEventListener('keydown', (event) => {
          if (event.keyCode === 9 && event.shiftKey) {
            this.closeSubMenu()
          }
        })
      }

      on(window, 'resize', () => {
        if (!this.isOver()) {
          subMenu.style.height = ''
          return
        }

        if (subMenu && this.isMenuOpened) {
          subMenu.style.height = `${subMenu.children[0].offsetHeight}px`
        }
      })
    },
    toggleShowListLinks(e) {
      const button = e.target.closest('.js-menu-item-submenu-list-title')
      const submenuItem = e.target.closest('.js-submenu-item')
      const groupLinks = submenuItem?.querySelector('.js-submenu-item-links')
      if (groupLinks) {
        if (groupLinks.classList.contains('is-expanded')) {
          button.setAttribute('aria-expanded', 'false')
          groupLinks.classList.remove('is-expanded')
          groupLinks.style.setProperty('--list-links-content-height', '0px')
        } else {
          button.setAttribute('aria-expanded', 'true')
          groupLinks.classList.add('is-expanded')
          groupLinks.style.setProperty('--list-links-content-height', `${groupLinks.scrollHeight}px`)
        }
      }
    },
    toggleSubmenu(e) {
      if (!this.isOver()) {
        const navMenuMobile = e.target.closest('.js-nav-menu-mobile')
        if (navMenuMobile) {
          if (e.target.tagName.toLowerCase() === 'a') {
            const href = e.target.href
            window.location = href
          }
        }
      }
    },
    closeOnWindowResize() {
      let windowWidth = window.innerWidth
      const onWindowChange = () => {
        if (window.innerWidth !== windowWidth) {
          windowWidth = window.innerWidth
          this.closeSubMenu()
        }
      }
      on(window, 'resize', onWindowChange)
      on(window, 'orientationchange', onWindowChange)
    },
    openSubmenuOnHover() {
      if (!this.isOver()) return
      this.openSubMenu()
    },
    closeSubmenuOnHover() {
      if (!this.isOver()) return
      this.closeSubMenu()
    },
    getSubmenuContentHeight() {
      return this.isMenuOpened && this.$refs.subMenu
        ? `${this.$refs.subMenu.scrollHeight}px`
        : '0px'
    },
  }
})
